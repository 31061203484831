/*
 * @Description: 常规列表mixin
 */
import { getFilterParams } from './_utils'

const TableMixin = {
  props: {
    getFilter: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      ApiController: {},
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      currentRow: {}
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    // 获取筛选数据
    getFetchParams() {
      return getFilterParams.call(this)
    },
    // 拉取数据
    async fetchData() {
      const params = this.getFetchParams()

      try {
        this.loading = true
        const { data, total } = await this.ApiController.getList({ ...params })
        this.tableData = data
        this.pagination.total = total
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    // 编辑元素
    handleAddEditItem(row = {}) {
      this.currentRow = { ...row }
      this.$nextTick(() => {
        this.$refs.AddEditItemDialog.handleShow(row)
      })
    },
    // 删除元素
    handleDeleteItem(row) {
      const _deleteAction = async () => {
        try {
          await this.ApiController.deleteItem(row)
          this.$message.success('删除成功!')
          this.fetchData()
        } catch (error) {
          console.log(error)
        }
      }

      const title = (h) => {
        return (
          <div>
            <span>确定要删除么？</span>
            <b style="color: #C03639;">删除之后不可恢复!</b>
          </div>
        )
      }

      this.$confirm({
        type: 'warning',
        title: title,
        onOk: _deleteAction
      })
    }
  }
}

export {
  TableMixin,
}

export default TableMixin
