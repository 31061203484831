var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table_area_wrapper flex_column" },
    [
      _c(
        "div",
        { staticClass: "custom_vxe_table_wrapper" },
        [
          _c(
            "vxe-table",
            {
              attrs: {
                height: "auto",
                data: _vm.tableData,
                loading: _vm.loading,
              },
            },
            [
              _c("vxe-column", {
                attrs: { title: "设备地址", field: "address" },
              }),
              _c("vxe-column", {
                attrs: {
                  title: "设备状态",
                  field: "status",
                  formatter: ["enum", _vm.EQUIPMENT_STATUS_ENUM],
                },
              }),
              _c("vxe-column", { attrs: { title: "设备编码", field: "code" } }),
              _c("vxe-column", {
                attrs: { title: "创建时间", field: "createtime" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("Pagination", {
        attrs: { pagination: _vm.pagination },
        on: {
          "update:pagination": function ($event) {
            _vm.pagination = $event
          },
          change: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }