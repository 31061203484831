var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "ContentLayout",
        [
          _c("FilterForm", {
            ref: "FilterForm",
            attrs: { slot: "top" },
            on: { search: _vm.updateTable },
            slot: "top",
          }),
          _c("MainTable", {
            ref: "MainTable",
            staticClass: "mt-10",
            attrs: { getFilter: _vm.getFilter },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }