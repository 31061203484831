<!--
 * @Description: 筛选表单
-->
<!--
 * @Description: 筛选表单
-->
<template>
  <a-card class="form_wrapper">
    <a-form-model :model="form" ref="form" v-bind="formLayout">
      <FilterLayout @reset="reset" @search="search">
        <a-form-model-item prop="address" label="设备地址">
          <a-input v-model="form.address"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="code" label="设备编码">
          <a-input v-model="form.code"></a-input>
        </a-form-model-item>

        <!--
        <a-form-model-item prop="organizationPhone" label="联系电话">
          <a-input v-model="form.organizationPhone"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="organizationUsername" label="联系人姓名">
          <a-input v-model="form.organizationUsername"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="startAndEnd" label="创建时间">
          <a-range-picker v-model="form.startAndEnd"></a-range-picker>
        </a-form-model-item>-->

        <a-form-model-item prop="status" label="设备状态">
          <a-select v-model="form.status" placeholder="全部" allowClear>
            <a-select-option
              v-for="value in EQUIPMENT_STATUS_ENUM.values"
              :key="value"
              :value="value"
            >{{ EQUIPMENT_STATUS_ENUM[value] }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </FilterLayout>
    </a-form-model>
  </a-card>
</template>

<script>
import { EQUIPMENT_STATUS_ENUM } from '@/const/EquipmentStatus'
import filterFormMixin from '@/mixins/filterFormMixin'

const FORM = () => ({
  address: '',
  code: '',
  organizationName: '',
  organizationPhone: '',
  organizationUsername: '',
  startAndEnd: [undefined, undefined],
  status: undefined
})

export default {
  mixins: [filterFormMixin],
  data() {
    return {
      EQUIPMENT_STATUS_ENUM,
      form: FORM()
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
