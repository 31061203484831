/*
 * @Description: 设备状态
 */

const EQUIPMENT_STATUS_ENUM = {
  STAND_BY: '就绪',
  RUNNING: '运行中',
  ERROR: '异常',
  OFFLINE: '离线',
  values: ['STAND_BY', 'RUNNING', 'ERROR', 'OFFLINE']
}

export {
  EQUIPMENT_STATUS_ENUM
}
export default EQUIPMENT_STATUS_ENUM
