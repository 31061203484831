<!--
 * @Description: 列表
-->
<template>
  <div class="table_area_wrapper flex_column">
    <!-- 表格加一层wrapper, 方便 height=auto 处理 -->
    <div class="custom_vxe_table_wrapper">
      <vxe-table height="auto" :data="tableData" :loading="loading">
        <vxe-column title="设备地址" field="address"></vxe-column>
        <vxe-column title="设备状态" field="status" :formatter="['enum', EQUIPMENT_STATUS_ENUM]"></vxe-column>
        <vxe-column title="设备编码" field="code"></vxe-column>
        <vxe-column title="创建时间" field="createtime"></vxe-column>
      </vxe-table>
    </div>

    <!-- 分页 -->
    <Pagination :pagination.sync="pagination" @change="fetchData" />
  </div>
</template>

<script>
import { EQUIPMENT_STATUS_ENUM } from '@/const/EquipmentStatus'
import ApiController from '@/views/equipment/api'
import tableMixin from '@/mixins/tableMixin'

export default {
  mixins: [tableMixin],
  components: {},
  data() {
    return {
      ApiController,
      EQUIPMENT_STATUS_ENUM
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
