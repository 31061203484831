<!--
 * @Description: 项目列表
-->

<template>
  <page-header-wrapper>
    <ContentLayout>
      <FilterForm slot="top" ref="FilterForm" @search="updateTable"></FilterForm>
      <MainTable class="mt-10" ref="MainTable" :getFilter="getFilter"></MainTable>
    </ContentLayout>
  </page-header-wrapper>
</template>

<script>
import FilterForm from './components/FilterForm.vue'
import MainTable from './components/MainTable.vue'

export default {
  name: 'ProjectList',
  components: {
    FilterForm,
    MainTable
  },
  data() {
    return {}
  },
  methods: {
    getFilter() {
      return this.$refs.FilterForm.form
    },
    updateTable() {
      this.$refs.MainTable.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn_area {
  text-align: right;
}

.page_content {
  display: flex;
  flex-direction: column;
}

.main_table {
  flex: 1;
}
</style>
