/*
 * @Description: 检测机构相关接口
 */
import { requestCustom as request } from '@/utils/request'

const BASE_URL = `/api/organization/devices`

// 获取列表
function getList(params) {
  return request({
    url: `${BASE_URL}`,
    method: 'get',
    params
  })
}

// 添加
function add(data) {
  return request({
    url: `${BASE_URL}`,
    method: 'post',
    data
  })
}

// 编辑
function edit(data) {
  return request({
    url: `${BASE_URL}`,
    method: 'put',
    data
  })
}

// 删除
function deleteItem(id) {
  return request({
    url: `${BASE_URL}/${id}`,
    method: 'delete'
  })
}

export default {
  getList,
  add,
  edit,
  deleteItem
}
